// en, de, fr, pt , tr , ar 
export default {
	tr: {
		Home:'Ana Sayfa',
		About: 'Biz',
		Services: 'Hizmetler',
		Faq: 'S.S.S',
		References: 'Referanslar',
		Contact: 'İletişim',
		Shop: 'Mağaza',
		I18n: 'I18n',
        },
	ar: {
		Home: 'الصفحة الرئيسية',
		About: 'About',
		Services: 'Services',
		Faq: 'Faq',
		References: 'References',
		Contact: 'Contact',
		Shop: 'Shop',
		I18n: 'I18n',
		},
	en: {
		Home:'Home Page',
		About: 'About',
		Services: 'Services',
		Faq: 'Faq',
		References: 'References',
		Contact: 'Contact',
		Shop: 'Shop',
		I18n: 'I18n',
		},
	
}
