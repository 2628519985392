/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

      {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '',
        component: () => import('./layouts/main/Main.vue'),
        children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
          {
            path: '/',
            name: 'Home',
            component: () => import('./views/pages/KnowledgeBase.vue')
          },
          {
            path: '/page2',
            name: 'page2',
            component: () => import('./views/Page2.vue')
          },
          {
            path: '/about',
            name: 'about',
            component: () => import('./views/pages/Profile.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'About', active: true },
              ],
              pageTitle: 'Hakkımızda',
              rule: 'editor'
            }
          },
          {
            path: '/services',
            name: 'services',
            component: () => import('./components/pages/services.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Services', active: true },
              ],
              pageTitle: 'Hizmetlerimiz',
              rule: 'editor'
            }
          },
          {
            path: '/faq',
            name: 'faq',
            component: () => import('./views/pages/Faq.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Faq', active: true },
              ],
              pageTitle: 'S.S.S',
              rule: 'editor'
            }
          },
          {
            path: '/download',
            name: 'download',
            component: () => import('./components/pages/download.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Download', active: true },
              ],
              pageTitle: 'İndir,İzle,Öğren',
              rule: 'editor'
            }
          },
          {
            path: '/references',
            name: 'references',
            component: () => import('./components/pages/references.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'References', active: true },
              ],
              pageTitle: 'Referanslar',
              rule: 'editor'
            }
          },
          //{
          //  path: '/apps/eCommerce/shop',
          //  name: 'shop',
          //  component: () => import('./views/apps/eCommerce/ECommerceShop.vue')
          //},
          {
            path: '/apps/eCommerce/checkout',
            name: 'eCommerceCheckout',
            component: () => import('./views/apps/eCommerce/ECommerceCheckout.vue')
          },
          {
            path: '/apps/eCommerce/wish-list',
            name: 'eCommerceWishList',
            component: () => import('./views/apps/eCommerce/ECommerceWishList.vue')
          },
          {
            path: '/contact',
            name: 'contact',
            component: () => import('./components/pages/contact.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'İletişim', active: true },
              ],
              pageTitle: 'İletişim',
              rule: 'editor'
            }
          },
          {
            path: '*',
            redirect: '/pages/error-404'
          }
        ],
      },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
      {
        path: '',
        component: () => import('@/layouts/full-page/FullPage.vue'),
        children: [
      // =============================================================================
      // PAGES
      // =============================================================================
          {
            path: '/pages/login',
            name: 'pageLogin',
            component: () => import('@/views/pages/Login.vue')
          },
          {
            path: '/pages/error-404',
            name: 'pageError404',
            component: () => import('@/views/pages/Error404.vue')
          },
        ]
      },
      // Redirect to 404 page, if no match found
      {
        path: '*',
        redirect: '/pages/error-404'
      }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
